<template>
  <div class="">
    <navbar title="投票"></navbar>
    <div class="box">
      <!-- 大标题 -->
      <div class="title">
        <div class="title-detail">
          {{ title }}
        </div>
      </div>
      <!-- 分割线 -->
      <div class="line"></div>
      <!-- 投票部分 -->
      <div class="sign">
        <img @click="goVoteDetail()" src="@/assets/img/vote-big.png" alt="" />
        <div>投票</div>
      </div>
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import tabbar from "@/components/tabBar.vue";
import navbar from "@/components/navBar.vue";
import { selectResultUserVote , searchVoteInfo} from "@/api/api.js";
import { Toast } from "vant";
export default {
  name: "answer",
  components: {
    tabbar,
    navbar,
  },
  data() {
    return {
      voteId: "",
      title: "",
      flag:true
    };
  },
  mounted() {
    // 截取本地url上的voteId
    this.interceptUrl()
  },
  methods: {
    // 截取本地url上的voteId
    interceptUrl(){
      var url = window.location.href;
      // var url = "http://localhost:8082/vote?voteId=99977509981913090&code=111&state=321"
      this.winUrl = url;
      // 如果是扫码进来的，则从路径上截取voteid。如果是直接点进来的，则走查询最近一条投票接口
      if (url.indexOf("voteId=") != -1) {
        var str = url.substr(1);
        var s = str.split("=");
        var strs = s[1].split("&");
        // voteId放入缓存
        localStorage.setItem('voteId', strs[0]);
        this.voteId = strs[0]
        //查询投票详情
        this.voteDetail()
      }else{
        // 移动端查询最近一条投票
        this.selectLatelyVote();
      }
    },
    // 移动端查询最近一条投票
    selectLatelyVote() {
      selectResultUserVote({
        openId:localStorage.getItem('openId'),
      }).then((res) => {
        if (res.data.code == 0) {
          this.title = res.data.data.name;
          if (res.data.data.id) {
            // 提取voteId，准备传给下个页面用
            this.voteId = res.data.data.id;
          }
        } else {
          Toast(res.data.msg);
          this.title = res.data.msg;
          this.flag = false
        }
      });
    },
    //查询投票详情
    voteDetail(){
        searchVoteInfo({
          voteId:this.voteId
        }).then((res)=>{
          if(res.data.code == 0){
            this.title = res.data.data.name
          }else{
            Toast(res.data.msg)
            if(res.data.msg == "当前投票已结束！"){
              this.title = res.data.msg
              this.flag = false
            }  
          }
        })
    },
    //跳转投票页
    goVoteDetail() {
      let that = this;
      if(this.flag == false){
        return
      }
      if (that.voteId != null && that.voteId != "" && that.voteId != undefined ) {
        this.$router.push({
          path: "/voteDetail",
          query: {
            voteId: that.voteId,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.title {
  width: 100%;
  height: 218px;
  position: relative;
}

.title-detail {
  width: 100%;
  text-align: center;
  position: absolute;
  color: #333333;
  font-size: 32px;
  font-family: Songti SC;
  font-weight: bold;
  top: 50%;
  transform: translateY(-50%);
}

.line {
  width: 100%;
  height: 20px;
  background: #faf9fb;
}

.sign {
  text-align: center;
}

.sign img {
  width: 300px;
  height: 300px;
  margin-top: 160px;
}

.sign div {
  margin-top: 40px;
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #505050;
}
</style>